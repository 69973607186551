$font-asset-path: '~@stitch-fix/kufak-fesk';

@import '~@stitch-fix/kufak-fesk/fonts/webfonts';
@import '~@stitch-fix/kufak-fesk/styles/mixins/all';
@import '~@stitch-fix/kufak-fesk/styles/variables/all';

@import '~@stitch-fix/mode-style-system';
@include reset;

// reset button styles so OneTrust link renders correctly
.For-CA-Users button {
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  user-select: text;
  color: #5077b9;
}
